@font-face {
  font-family: 'FC-Hype-Regular';
  src: url('../src/fonts/FCHype/FCHype-Regular.woff2') format('woff2'),
      url('../src/fonts/FCHype/FCHype-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FC-Hype-Bold';
  src: url('../src/fonts/FCHype/FCHype-Bold.woff2') format('woff2'),
      url('../src/fonts/FCHype/FCHype-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FC-Hype-Ligh';
  src: url('../src/fonts/FCHype/fc_hype_light_ver_1.01-webfont.woff2') format('woff2'),
       url('../src/fonts/FCHype/fc_hype_light_ver_1.01-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'FC-Hype-Medium';
  src: url('../src/fonts/FCHype/FCHype-Medium.woff2') format('woff2'),
      url('../src/fonts/FCHype/FCHype-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FC-Hype-SemiBold';
  src: url('../src/fonts/FCHype/FCHype-SemiBold.woff2') format('woff2'),
      url('../src/fonts/FCHype/FCHype-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-TH-Bold';
  src: url('../src/fonts/GraphikTH/GraphikTH-Bold.woff2') format('woff2'),
      url('../src/fonts/GraphikTH/GraphikTH-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-TH-Semibold';
  src: url('../src/fonts/GraphikTH/GraphikTH-Semibold.woff2') format('woff2'),
      url('../src/fonts/GraphikTH/GraphikTH-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-TH-Regular';
  src: url('../src/fonts/GraphikTH/GraphikTH-Regular.woff2') format('woff2'),
      url('../src/fonts/GraphikTH/GraphikTH-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Flexa-Bold';
  src: url('../src/fonts/GTFlexa/GTFlexa-Bd.woff2') format('woff2'),
      url('../src/fonts/GTFlexa/GTFlexa-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Flexa-Light';
  src: url('../src/fonts/GTFlexa/GTFlexa-Lt.woff2') format('woff2'),
      url('../src/fonts/GTFlexa/GTFlexa-Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Flexa-Regular';
  src: url('../src/fonts/GTFlexa/GTFlexa-Rg.woff2') format('woff2'),
      url('../src/fonts/GTFlexa/GTFlexa-Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Flexa-Thin';
  src: url('../src/fonts/GTFlexa/GTFlexa-Th.woff2') format('woff2'),
      url('../src/fonts/GTFlexa/GTFlexa-Th.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Flexa-Medium';
  src: url('../src/fonts/GTFlexa/GTFlexa-Md.woff2') format('woff2'),
      url('../src/fonts/GTFlexa/GTFlexa-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Super-Display-Bold';
  src: url('../src/fonts/GTSuperDisplay/GTSuperDisplay-Bold.woff2') format('woff2'),
      url('../src/fonts/GTSuperDisplay/GTSuperDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Super-Display-Regular-Italic';
  src: url('../src/fonts/GTSuperDisplay/GTSuperDisplay-RegularItalic.woff2') format('woff2'),
      url('../src/fonts/GTSuperDisplay/GTSuperDisplay-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT-Super-Display-Regular';
  src: url('../src/fonts/GTSuperDisplay/GTSuperDisplay-Regular.woff2') format('woff2'),
      url('../src/fonts/GTSuperDisplay/GTSuperDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'GT-Super-Display-Light-Italic';
  src: url('../src/fonts/GTSuperDisplay/gtsuperdisplay-lightitalic-webfont.woff2') format('woff2'),
       url('../src/fonts/GTSuperDisplay/gtsuperdisplay-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}  

body {
  font-size: 14px;
  letter-spacing: normal!important;
}
p {
  font-size: 14px;
  line-height: 150%;
}
h6 {
  font-size: 1.25rem!important;
}
main {
  position: relative;
  overflow-y: visible!important;
}
header {
  z-index: 1900!important;
  height: 50px!important;
  position: sticky!important;
  top: 0;
}
header button {
  padding: 0 10px!important;
}
header .MuiToolbar-regular {
  min-height: 50px;
}
.headline {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 15px;
}
.MuiMenu-list li:hover p {
  color: #000;
}
.input-text textarea {
  resize: none;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
  font-weight: bold;
  line-height: 1.4;
  font-size: 40px;
  padding-bottom: 6px;
  border: 0;
  /* border-bottom: 1px solid #000; */
}
.input-text-lg {
  width: 100%;
}
.avatar-circular {
  text-align: center;
}
.avatar-circular .MuiAvatar-circular {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.avatar-circular input[type="file"] {
  display: block;
  margin: 20px auto;
}
.container-editor, .container-md {
  position: relative;
  display: block;
  width: 100%;
  max-width: 680px;
  margin: 0 auto!important;
}
.container-editor.box-shadow {
    padding: 20px;
    border: 1px solid;
    box-shadow: 0 0 4px 2px rgba(230, 230, 230, 0.5);
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    margin-bottom: 20px;
}
.container-history ul {
  padding: 0;
  border-bottom: 1px solid #eee;
}
.MuiBreadcrumbs-ol {
  margin: 10px 0!important;
}
.MuiIconButton-colorPrimary,
.MuiSvgIcon-colorPrimary,
.MuiTypography-colorPrimary {
  color: #252525!important;
}
.MuiIconButton-root {
  color: #252525!important;
}
.makeStyles-root-35,
.makeStyles-demo1-36,
.makeStyles-padding-37 {
  position: relative;
}
.sticky {
  width: 100%;
  position: sticky;
  z-index: 1000;
  top: 50px;
  background: #FFF;
}
.filter-addcontent {
  position: relative;
  padding: 15px;
  margin-bottom: 10px;
}
.filter-search.top-search {
  display: flex;
  justify-content: space-between;
}
/* .filter-addcontent .MuiPaper-rounded {
  padding: 0!important;
} */
.filter-search a {
  margin: 0;
  white-space: nowrap;
}
.box-filed-topic {
  padding: 15px;
  display: grid;
  grid-gap: 20px;
}
.panorama-container ul {
  background: transparent;
}
.btn-save {
  background-color: #252525!important;
}
.btn-save svg {
  color: #FFF;
}
.MuiTab-root {
  letter-spacing: normal!important;
}
.logo-mirror {
  width: 120px!important;
  height: auto!important;
  margin: 24px auto;
  text-align: center;
}
.logo-main-mirror {
  width: 170px!important;
  height: auto!important;
  text-align: center;
  margin: 0 auto 20px;
}
.logo-main-mirror h5 {
  font-weight: normal!important;
  color: #000!important;
}
.login-main form {
  border-radius: 8px;
  display: grid;
  grid-gap: 15px;
  background-color: #FFF;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.login-main .MuiFormControl-fullWidth, .login-main .MuiButton-contained {
  width: 100%;
  margin: 0;
}
.login-main label {
  margin: 0;
}
.login-main .MuiButton-label {
  font-size: 16px;
  font-weight: bold;
}
.login-main .MuiButton-label span {
  display: none;
}
._mirror-theme-rwd h3, ._mirror-theme-rwd h4, ._mirror-theme-rwd h5, ._mirror-theme-rwd h6 {
  font-weight: bold;
  letter-spacing: normal;
}
._mirror-theme-rwd .MuiTableHead-root th {
  font-weight: bold!important;
  background-color: #eee;
}
._mirror-theme-rwd .MuiTableHead-root th .MuiButton-label {
  font-weight: bold!important;
  white-space: nowrap;
}
._mirror-theme-rwd .MuiTableHead-root th .MuiButton-text {
  margin: 0;
  padding: 0;
}
._mirror-theme-rwd .Mui-selected {
  font-weight: bold!important;
  color: #000!important;
}
._mirror-theme-rwd hr {
  margin-inline: 0!important;
}
._mirror-theme-rwd .MuiDivider-vertical {
  margin: 8px 10px!important;
}
._mirror-theme-rwd .MuiGrid-item h6 {
  margin-left: 0!important;
}
._mirror-theme-rwd .MuiBreadcrumbs-ol li {
  font-size: 12px;
}
._mirror-theme-rwd .MuiBreadcrumbs-ol li a {
  color: #000;
  font-weight: bold;
}
._mirror-theme-rwd .form-setting p {
  color: #000;
}
._mirror-theme-rwd .form-setting .MuiGrid-grid-xs-3 {
  max-width: 35%;
  flex-basis: 35%;
}
._mirror-theme-rwd .form-setting .MuiGrid-spacing-xs-1 > .MuiGrid-item{
  padding: 0;
}
._mirror-theme-rwd .form-setting .MuiGrid-grid-xs-9 {
  max-width: 65%;
  flex-basis: 65%;
}
._mirror-theme-rwd .author-form form {
  padding: 20px 0;
}
.overflow-hidden {
  overflow: hidden;
}

.form-grid  .MuiGrid-item {
  padding: 5px 15px;
}

/* header */
.header-h3 {
  margin: 0;
  padding: 0;
  font-weight: bold!important;
  color: #000;
}

.MuiButton-startIcon svg {
  color: #FFF;
}

/* table */
table a {
  color: #000;
}
table th {
  white-space: nowrap;
}
table tbody tr:nth-child(even) {
  background: #F8F8F8;
}
table th:first-of-type {
  text-align: left;
}
table tbody td {
  font-size: 14px!important;
  padding: 8px 15px!important;
}
table tbody td:first-of-type a {
  width: auto!important;
}
.table-quote tbody td a.MuiLink-underlineHover {
  text-decoration: none;
  word-break: break-all;
  display: block;
  width: 200px;
  line-height: 140%;
}
.table-quote thead th:first-of-type,
.table-quote tbody td:first-of-type {
  width: 300px;
}

/* Panorama */
.panorama-container {
  overflow: hidden;
}
.panorama-container ul {
  overflow: hidden;
  width: 100%;
}
.grid-panorama {
  display: flex;
  gap: 20px;
  overflow-y: scroll;
  width: 100%;
}
.list-panorama {
  width: 100%;
  position: relative;
}
.list-panorama ul, .list-quote ul {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.list-panorama ul li {
  width: 100%;
}
.item-panorama ul li .MuiListItem-root,
.list-panorama ul li .MuiListItem-root {
  min-height: 110px;
  margin: 0;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 120px 1fr;
}
.list-panorama ul li span {
  white-space: normal;
}
.list-panorama ul li a {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  max-height: 300px;
  height: 100%;
  margin-bottom: 5px;
}

 /* panorama */
.item-panorama li .MuiListItem-root {
  padding: 0 0 30px 0;
  margin-bottom: 0;
  grid-gap: 10px;
  display: grid;
  width: 230px;
  background-color: #fafafa;
  grid-template-columns: 1fr;
}
.item-panorama li .MuiAvatar-rounded {
  padding-top: 56.25%;
}
.item-panorama li .MuiAvatar-rounded img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 101%;
  height: 101%;
  object-fit: cover;
}
.item-panorama li .MuiAvatar-rounded,  .item-panorama li .MuiListItemAvatar-alignItemsFlexStart{
  position: relative;
  width: 100%!important;
  height: 100%!important;
  margin: 0;
}
.item-panorama li .MuiListItemSecondaryAction-root {
  right: 6px!important;
  bottom: 6px;
  position: absolute;
  top: inherit;
  transform: translateY(0);
}
.item-panorama ul li p, .list-panorama ul li p {
  line-height: 1.4;
}
.item-panorama li a, .list-panorama ul li a {
  font-weight: bold;
  color: #000;
}
.list-panorama ul li .MuiListItem-root {
  padding: 0;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}
.list-panorama ul li .MuiAvatar-rounded,  .list-panorama ul li .MuiListItemAvatar-alignItemsFlexStart{
  position: relative;
  width: 100%!important;
  height: 100%!important;
  margin: 0;
}
.list-panorama ul li .MuiAvatar-rounded {
  padding-top: 56.25%;
}
.list-panorama ul li .MuiAvatar-rounded img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 101%;
  height: 101%;
  object-fit: cover;
}
.list-panorama ul li .MuiListItemText-multiline {
  margin: 0;
  padding: 0 15px 30px 15px;
}
.list-panorama ul li .MuiListItemSecondaryAction-root {
  right: 6px!important;
  bottom: 6px;
  position: absolute;
  top: inherit;
  transform: translateY(0);
}
.list-panorama ul li .MuiIconButton-label {
  background-color: #01b400;
  border-radius: 50%;
}
.item-panorama li .MuiIconButton-label {
  background-color: #f44336;
  border-radius: 50%;
}
.item-panorama li .MuiIconButton-label .MuiSvgIcon-colorError {
  color: #FFF;
}
.icon-plus svg {
  color: #FFF;
}
.group-pano {
  overflow: hidden;
  margin-bottom: 30px!important;
}
.group-pano:first-of-type {
  padding-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: #efeeee;
  border-bottom: 1px solid #eee;
}
.group-pano:first-of-type .MuiGrid-container {
  padding: 10px 0;
  align-items: center;
}
.group-pano:first-of-type .MuiGrid-container button {
  margin: 0!important;
}
.search-pano {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.search-pano h6 {
  text-align: left;
}
.filter-addcontent .MuiCollapse-wrapper .MuiPaper-root,
.form-pano .MuiCollapse-wrapper .MuiPaper-root {
  margin: 10px auto 10px;
  background-color: #fafafa;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.filter-addcontent .MuiCollapse-wrapper .MuiPaper-root {
  max-width: 680px;
  margin: 20px 0 20px 0;
}
.field-form-panno {
  /* padding: 15px; */
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.field-form-panno .MuiIconButton-root {
  padding: 0;
}

.video-rwd {
  margin-top: 20px;
}
.video-rwd {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-rwd iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-quote li  {
  position: relative;
  height: 320px;
  overflow: hidden;
  padding: 10px 10px 44px 10px;
  border: 1px solid #dfdfdf;
  background: white;
  min-height: auto;
  border-radius: 4px;
}
.card-quote li a {
  color: #000;
}
.card-quote li span, .card-article li span {
  line-height: 1.3;
}
.card-quote li a, .card-article li a {
  font-size: 14px;
  line-height: 1.3;
}
.card-quote li a {
  font-weight: normal!important;
}
.card-quote li > div, .card-article li > div {
  height: 100%;
  overflow: hidden;
}
.card-quote li > div > div {
  padding: 0;
  margin: 0;
}
.card-quote li .MuiListItemSecondaryAction-root, .card-article li .MuiListItemSecondaryAction-root {
  height: auto;
}
.card-quote li > div {
  padding: 0;
  margin: 0;
  align-items: flex-start;
}
.card-quote li .MuiIconButton-label {
  background-color: #01b400;
  border-radius: 50%;
}
.card-quote li .MuiListItemSecondaryAction-root {
  top: inherit;
  bottom: 10px;
  right: 16px;
  transform: translateY(0);
}

.MuiButton-contained.Mui-disabled {
  color: #FFF!important;
  background-color: rgba(0, 0, 0, 0.12)!important;
}

/* list product */
.product-container {
  position: relative;
}
.product-select {
  padding: 20px;
  border-radius: 4px;
  background-color: #efeeee;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}
.product-select .header-h3 {
  line-height: 200%;
}
.list-product {
  display: flex;
}
.list-product h6,
.list-product h6 a {
  font-size: 14px;
  color: #000;
}
.list-product > div {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  overflow-x: scroll;
}
.card-product {
  width: 300px;
}
.list-product-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
.list-product-grid h6 a {
  color: #000;
  font-size: 14px;
}
.list-product-grid .MuiIconButton-sizeSmall,
.list-product .MuiIconButton-sizeSmall {
  width: 24px;
  height: 24px;
  color: #FFF;
  background-color: #01b400;
  border-radius: 50%;
}
.MuiIconButton-label .MuiSvgIcon-colorError {
  color: #FFF;
  background-color: #f44336;
  border-radius: 50%;
}
.list-product-grid span svg {
  color: #FFF;
}

/* edit style */
.editor-style a, .fr-element a {
  color: #000;
}
.editor-style { 
    position: relative;
    font-family: "thongterm",sans-serif!important;
    line-height: 34px;
    color: #262626;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.editor-style h1, .editor-style h2, .editor-style h3, .editor-style h4 {
      font-family: Graphik-TH-Semibold;
      line-height: 40px;
      color: #000;
      margin: 1em 0;
  }
.editor-style h1 {
    font-size: 30px;
}
.editor-style h2 {
    font-size: 26px;
}
.editor-style h3 {
    font-size: 21px;
}
.editor-style h4 {
    font-size: 18px;
    line-height: 30px;
}
.editor-style strong, .editor-style b {
    font-weight: bold;
}
.editor-style p {
    margin: 1em 0;
    font-family: "thongterm",sans-serif!important;
    word-break: break-word;
    color: #262626;
    font-size: 18px;
    line-height: 34px;
    font-weight: normal;
}
.editor-style a {
    color: #000;
    text-decoration: underline;
}
.editor-style a span {
  line-height: 1;
}
.editor-style a:focus {
  outline: 0;
}
.editor-style img {
    width: auto;
    max-width: 100%;
}
.editor-style ol li, .editor-style ul li {
  line-height: 34px;
}
.editor-style ol {
    display: block;
    margin: 0 0 0 22px;
    padding: 0;
}
.editor-style ol li {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 4px;
}
.editor-style ul {
  display: block;
  margin: 0 0 0 22px;
  padding: 0;
}
.editor-style ul li {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 4px;
}
.editor-style .qa_widget_title {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 20px;
    background-color: #dcf0fa;
}
.editor-style .qa_widget_title:before, .editor-style .qa_widget_title:after{
    content: " ";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}
.editor-style .qa_widget_title:before {
    margin-right: 10px
}  
.editor-style .qa_widget_title:after {
    margin-left: 10px
}
.editor-style .qa_widget_label {
    margin: 0.5em 0!important;
    font-family: GT-Super-Display-Light-Italic !important;
    font-weight: normal;
    font-size: 40px;
    text-align: center;
    font-family: initial;
}
.editor-style .qa_widget_question {
    margin: 0.5em 0 0 0!important;
    font-family: Graphik-TH-Semibold !important;
    font-style: normal!important;
    text-align: center;
    font-size: 21px!important;
    background-color: transparent;
}
.editor-style hr {
  display: none;
}
.editor-style .fr-view span.fr-img-caption.fr-dib {
    margin: 5px auto;
}
.editor-style .fr-img-caption {
    width: 100%!important;
    display: block;
    text-align: center;
}
.editor-style .fr-img-caption a {
  text-decoration: none;
}
.editor-style .fr-img-caption a:focus {
    outline: 0;
    border: 0;
    text-decoration: none;
}
.editor-style  .fr-img-caption a img {
  display: block;
}
.editor-style .fr-img-caption p {
  margin: 0!important;
}
.editor-style .fr-box.fr-basic .fr-element {
  color: #000;
  font-family: "thongterm",sans-serif!important;
}
.editor-style .fr-img-caption .fr-inner  {
    padding: 15px 0 0 0;
    display: block;
    font-family: "thongterm",sans-serif!important;
    line-height: 1!important;
    color: #000!important;
    font-size: 14px!important;
    text-align: center!important;
}
.editor-style .elle_quote {
    margin: 20px 0;
    padding: 20px;
    font-family: FC-Hype-Ligh !important;
    font-size: 28px;
    letter-spacing: -0.14px;
    line-height: 40px;
    border: 10px solid rgb(0, 0, 0);
}
.editor-style .elle_quote p {
    margin: 0;
    font-family: FC-Hype-Ligh !important;
    font-weight: normal!important;
    font-size: 28px;
    letter-spacing: -0.14px;
    line-height: 40px;
}
.elle_quote p.blockquote {
  padding: 0!important;
}
.editor-style .elle_quote p.blockquote::before {
  display: none;
}
/* table */
.editor-style table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #000;
}
.editor-style table th, table td {
  border: 0!important;
  line-height: 26px!important;
}
.editor-style table th {
  color: #fff;
  font-weight: bold;
  background: #000!important;
}
.editor-style table thead tr, .editor-style table th {
  color: #fff;
  font-weight: bold;
  background: #000;
}
.editor-style table th {
  padding: 8px 5px!important;
}
.editor-style table tbody tr {
  border: 0;
  border-bottom: 1px solid #DBDBDB!important;
}
.editor-style table tbody tr:last-of-type {
  border-bottom: 0!important;
}
.editor-style table tbody tr:nth-child(even) {
  background: #FAFAFA;
}
.editor-style table tbody tr td {
  padding: 8px 5px;
}
.editor-style table td.fr-thick, .editor-style table th.fr-thick {
  border: 0;
}

.footnote {
  margin-top: 10px!important;
  font-size: 12px!important;
  color: #777;
}

/* RWD */

@media (min-width: 1200px) { 
  .list-panorama ul {
    grid-template-columns: repeat(5, 1fr);
  }
  .list-quote ul {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) { 
  .list-quote ul {
    grid-template-columns: repeat(3, 1fr);
  }
  .list-product-grid {
    grid-template-columns: repeat(2, 1fr);
}
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  .filter-addcontent .filter-search.top-search form {
    width: 500px;
  }
}

@media (min-width: 992px) { 
  .fr-sticky-on {
    top: 50px!important;
  }
  .editor-style img.fr-dii.fr-fil {
      float: left;
      margin: 30px 30px 30px 0;
      max-width: calc(100% - 5px);
  }
  .editor-style img.fr-dii.fr-fir {
      float: right;
      margin: 30px 0 30px 30px;
      max-width: calc(100% - 5px);
  }
  .editor-style table th {
    padding: 10px 15px!important;
  }
  .editor-style table tbody tr td {
    padding: 8px 15px;
    border-right: 1px solid #DBDBDB!important;
    border-bottom: 0;
  }
  .editor-style table tbody tr td:last-of-type {
    border-right: 0!important;
  }
}

@media (min-width: 768px) { 
  .filter-addcontent .filter-search.top-search form {
    width: 500px;
  }
}


@media (max-width: 991.98px) { 
  
  .input-text textarea {
    font-size: 20px!important;
  }

  .list-product-grid {
    grid-template-columns: 1fr 1fr;
  }

  /* table */
  .MuiTable-root tbody td {
    padding: 12px;
  }
  .filter-addcontent {
    padding: 15px 15px 0 15px;
  }
  .fr-toolbar .fr-btn-grp {
    margin: 0;
  }
  .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn {
    margin: 0;
  }
  .fr-toolbar .fr-command.fr-btn i, .fr-toolbar .fr-command.fr-btn svg, .fr-popup .fr-command.fr-btn i, .fr-popup .fr-command.fr-btn svg, .fr-modal .fr-command.fr-btn i, .fr-modal .fr-command.fr-btn svg {
    margin: 8px 5px;
  }
 .fr-sticky-on.fr-sticky-ios, .fr-sticky-ios {
    position: sticky!important;
    top: 50px!important;
    z-index: 800;
    width: 100%!important;
  }
  .fr-sticky-dummy {
    height: 0!important;
  }
  .editor-sticky {
    position: sticky!important;
    top: 100px!important;
    height: 100px;
    background: #ccc;
  }
  .editor-style iframe {
      width: 100%;
  }
  .sidebar-left nav {
    padding: 0 15px;
  }
  .login-main form {
    padding: 20px;
  }
  .login-main .MuiBox-root {
    display: grid;
  }
  .login-main .MuiBox-root .MuiBox-root {
    border-radius: 0;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ._mirror-theme-rwd .MuiTable-root th {
    padding: 12px;
  }
  ._mirror-theme-rwd .MuiButton-outlined {
    padding: 5px 7px;
  }
  ._mirror-theme-rwd .MuiTab-root {
    margin-right: 0!important;
    min-width: auto!important;
    max-width: none!important;
    color: #000!important;
  }

  ._mirror-theme-rwd .Mui-selected .MuiTab-wrapper {
    font-weight: bold;
  }
  ._mirror-theme-rwd .MuiTabs-scroller {
    overflow-x: scroll!important;
  }
  ._mirror-theme-rwd .MuiListItem-gutters {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  ._mirror-theme-rwd .MuiInputBase-input {
    font-size: 14px;
  }
  ._mirror-theme-rwd .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 0 0 15px 0!important;
  }
  ._mirror-theme-rwd .MuiFormControl-root {
    margin-left: 0;
  }
  ._mirror-theme-rwd .MuiBreadcrumbs-root {
    display: block;
  }
  ._mirror-theme-rwd .MUIDataTableBodyCell-stackedCommon-127:nth-last-child(2) {
    font-weight: bold;
  }

  ._mirror-theme-rwd .author-form .MuiGrid-container {
    width: 100%!important;
    margin: 0!important;
  }
  ._mirror-theme-rwd .author-form .MuiAvatar-circular {
    margin: 0 auto;
  }
  ._mirror-theme-rwd .author-form input[type="file"] {
    text-align: center;
  }
  .list-panorama ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .list-quote ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .grid-panorama, .list-panorama ul, .list-quote ul {
    grid-gap: 12px;
  }
  .search-pano {
    grid-template-columns: 120px 1fr;
  }
  .product-select,
  .group-pano:first-of-type {
    margin: 0 -32px;
    width: 100vw;
  }
  .product-select {
    margin-bottom: 20px;
  }
  .panorama-container {
    margin-right: -24px;
  }
  /* .editor-style {
    position: sticky;
    top: 100px;
  } */
}

@media (max-width: 767.98px) { 

  .list-product-grid {
    grid-gap: 10px;
  }
  .list-product > div {
    gap: 10px;
    margin-bottom: 0;
  }
  .navbar-img-avatar .MuiAvatar-circular {
    width: 28px!important;
    height: 28px!important;
  }
  .editor-style h1 {
      font-size: 28px;
  }
  .editor-style h2 {
      font-size: 24px;
  }
  .editor-style .editor-style h3 {
        font-size: 18px;
    }
  .editor-style p {
        font-size: 16px;
    }
  .editor-style .elle_quote {
        font-size: 22px;
        line-height: 34px;
        border: 6px solid rgb(0, 0, 0);
  }
  .editor-style .elle_quote p {
      font-size: 22px;
      line-height: 34px;
  }
  .editor-styleul li, .editor-style ol li {
    font-size: 16px;
  }
  .editor-style table td {
    width: auto!important;
  }
  
  ._mirror-theme-rwd .MuiButton-outlined {
    margin: 0;
    min-width: auto;
    font-size: 12px;
    white-space: nowrap;
  }
  ._mirror-theme-rwd .MuiBreadcrumbs-ol {
    white-space: nowrap;
  }
  ._mirror-theme-rwd .MuiBreadcrumbs-ol li {
    font-size: 9px;
  }
  ._mirror-theme-rwd .MuiPaginationItem-root {
    min-width: 25px!important;
    height: 25px;
    margin: 0!important;
  }
  ._mirror-theme-rwd .MuiTablePagination-actions {
    margin: 0!important;
  }
  ._mirror-theme-rwd .MuiTablePagination-actions .MuiIconButton-root {
    padding: 8px;
  }
  ._mirror-theme-rwd .author-form .MuiAvatar-circular {
    width: 90px;
    height: 90px;
  }
  .list-panorama ul, .list-quote ul {
    grid-template-columns: 1fr 1fr;
  }
  .item-panorama li .MuiListItem-root {
    width: 165px;
    margin-bottom: 0;
  }
  .search-pano {
    grid-template-columns: 1fr;
  }
  .filter-search {
    justify-content: inherit;
    display: grid;
    grid-gap: 10px;
  }
  .filter-search.top-search {
    justify-content: inherit;
    display: grid;
    grid-gap: 10px;
  }
  .filter-search .MuiGrid-item {
    margin-top: 5px;
    grid-row: 1;
    text-align: right;
  }
  .filter-search .MuiGrid-item a {
    margin: 0 0 0 auto;
  }
  .container-md {
    padding-left: 20px;
    padding-right: 20px;
  }
  .form-grid .MuiGrid-item {
    padding: 10px 0;
  }
  .MuiGrid-align-items-xs-center {
    justify-content: center;
  }
  .headline {
    text-align: center;
  }
  .list-product-grid {
    grid-template-columns: 1fr;
  }
}
