/* @import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap'); */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Kanit', sans-serif
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* EditorJs padding */
.codex-editor__redactor{
  padding-bottom: 40px !important;
}
.MuiInput-underline:after {
  border-bottom:'border-bottom: 2px solid red';
}