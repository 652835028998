/* div.fr-wrapper > div > a {
  font-size: 0px !important;
  padding: 0px !important;
  height: 0px !important;
} */

.elle_quote {
  position: relative;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.elle_quote p.blockquote {
  font-family: 'kalatexa-regular';
  font-size: 20px;
  border-left: initial;
  margin-left: initial;
  padding: 5px 30px;
  color: initial;
}
.elle_quote p.blockquote::before {
  content: url('https://plus.thairath.dev/static/images/icon/open-quote.svg');
  position: absolute;
  top: 15px;
  left: 0;
  width: 26px;
  height: 26px;
}
.elle_quote p.blockquote::after {
  content: url('https://plus.thairath.dev/static/images/icon/close-quote.svg');
  position: absolute;
  bottom: 15px;
  right: 0;
  width: 26px;
  height: 26px;
}

.elle_quote .elle_quote-share {
  position: relative;
  display: flex;
}

.elle_quote .elle_quote-share > span {
  color: #a0a0a0;
  font-size: 16px;
  font-family: 'kalatexa-regular';
}

.elle_quote .elle_quote-share ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
}
