
.qa_widget_title {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 20px;
  background-color: #dcf0fa;
}

.qa_widget_title:before, .qa_widget_title:after{
  content: " ";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.qa_widget_title:before {
  margin-right: 10px
}

.qa_widget_title:after {
  margin-left: 10px

}

.qa_widget_label {
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  font-family: initial;
}

.qa_widget_question {
  text-align: center;
  font-style: italic;
  font-size: 18px;
  background-color: #dcf0fa;
}

.qa_widget_answer {
  /* background-color: #dcf0fa; */
}